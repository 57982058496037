import React, { useEffect, useState, useRef } from "react";
// import "./basicinfo.css";
import "./UploadJsonLanding.css"
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Item } from "react-stately";
import ComboBox from "../ComboBox/ComboBox";
import Dropzone from 'react-dropzone';
import { useMsal } from "@azure/msal-react";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";
import DOMPurify from 'dompurify';
import { CircularProgress } from "@mui/material";
import { error } from "highcharts";

const UploadJsonLanding = () => {
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState("");
  const [customAccountName, setCustomAccountName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [industryId, setIndustryId] = useState(0);
  const [projectURL, setProjectURL] = useState("");
  const [allAccounts, setAllAccounts] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [disableIndustryInput, setDisableIndustryInput] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [testId, setTestId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [fileError, setFileError] = useState(false);

  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const location = useLocation();
  const { state } = location;
  const { execute } = useFetchWithMsal({
    scopes: [
      ...protectedResources.getAllCompanies.scopes.read,
      ...protectedResources.addNewTest.scopes.write,
    ],
  });

  const capabilityValuesToId = {
    UX: 1,
    VisualDesign: 2,
    XT: 3,
    Triage: 4,
    Copy: 5,
    NA: 6,
  };

  useEffect(() => {
    execute("GET", protectedResources.getAllCompanies.endpoint, protectedResources.getAllCompanies.scopes).then(
      (response) => {
        if (response) {
          setAllAccounts(response);
        }
      }
    );

    execute("GET", protectedResources.getIndustries.endpoint, protectedResources.getIndustries.scopes).then(
      (response) => {
        if (response) {
          setIndustries(response);
        }
      }
    );
  }, [execute]);

  const getCompanyId = async () => {
    try {
      const response = await execute("GET", protectedResources.getCompany.endpoint, protectedResources.getCompany.scopes);
      if (response) {
        for (let i = 0; i < response.length; i++) {
          if (accountName && response[i].companyname.toUpperCase() === accountName.toUpperCase()) {
            return response[i].companyid;
          }

          if (customAccountName && response[i].companyname.toUpperCase() === customAccountName.toUpperCase()) {
            return response[i].companyid;
          }
        }

        // create new company if not in the list
        const body = {
          companyname: DOMPurify.sanitize(customAccountName),
          industryid: industryId
        };

        const newCompany = await execute("POST", protectedResources.addCompany.endpoint, protectedResources.addCompany.scopes, body);
        return newCompany[0].companyid;
      }
    } catch (error) {
      console.error("Error: ", error);
      throw error;
    }
  };

  function getDateFromString(dateString) {
    const date = new Date(`${dateString}`);
    if (isNaN(date)) {
      console.error("An error occured during submission:", error);
    }
    return date;
  }

  const onFileUpload = (file) => {
    setFileUploaded(true);
    setFileName(file.name);
    setFileError(false);

    const reader = new FileReader();
    reader.onload = function () {
      try {
        const json = JSON.parse(reader.result);
        setJsonData(json);

        const firstKey = Object.keys(json)[0]; // Dynamically get the first key
        const firstElement = json[firstKey];

        if (firstElement && firstElement.displayName) {
          setProjectName(firstElement.displayName);
        }
        if (firstElement && Array.isArray(firstElement.questions)) {
          setQuestions(firstElement.questions);
        }
        if (firstElement && firstElement.date) {
          setDate(getDateFromString(firstElement.date));
        }
        if (firstElement && firstElement.baseURL) {
          setProjectURL(firstElement.baseURL);
        }
      } catch (error) {
        console.error("Invalid JSON file", error);
        setFileUploaded(false);
        setFileName("");
        setFileError(true);
      }
    };

    reader.onerror = function (error) {
      console.error("Error reading file", error);
    }

    reader.readAsText(file);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!fileUploaded) {
      setFileError(true);
      setLoading(false);
      return;
    }

    try {
      const companyId = await getCompanyId();

      const test = {
        userid: account.localAccountId,
        username: account.name,
        companyid: companyId,
        testversionid: 1,
        testscore: 0,
        date: date,
        projectname: DOMPurify.sanitize(projectName),
        projecturl: DOMPurify.sanitize(projectURL),
      };

      const response = await execute("POST", protectedResources.addNewTest.endpoint, protectedResources.addNewTest.scopes, test);

      if (response && response[0] && response[0].testid) {
        setTestId(response[0].testid);

        for (const [index, question] of questions.entries()) {
          if (question) {
            const resp = {
              criteriaid: index + 1, // criteriaid in database starts at 1
              testid: response[0].testid,
              url: question.testedURL ? question.testedURL : "",
              notes: question.testNotes ? question.testNotes : "",
              responsescore: question.weight ? question.weight : -1,
              capabilityid: question.capability ? capabilityValuesToId[question.capability] : capabilityValuesToId["NA"],
            };

            await execute("POST", protectedResources.sendOrUpdateResponse.endpoint, protectedResources.sendOrUpdateResponse.scopes, resp);
          }
        }
        const completeTest = {
          testid: response[0].testid,
        };

        const res = await execute(
          "PUT",
          protectedResources.updateCompletedTest.endpoint,
          protectedResources.updateCompletedTest.scopes,
          completeTest
        );

        navigate("/Results", {
          state: { id: response[0].testid, showBackButton: false, score: res?.score },
        });
      } else {
        console.error("Error uploading test from JSON");
      }
    } catch (error) {
      console.error("An error occured during submission:", error);
    } finally {
      setLoading(false);
    }
  };

  const AccountNameLabel = () => {
    return (
      <label className="biform" htmlFor="accountName" id="accountNameLabel" aria-describedby="addInfo1">
        Account Name<span className="required_label">*</span>
      </label>
    );
  };

  const IndustryNameLabel = () => {
    return (
      <label className="biform" htmlFor="industryName" id="industryNameLabel">
        Industry Name<span className="required_label">*</span>
      </label>
    );
  };

  const JsonUploadLabel = () => {
    return (
      <label className="biform center" htmlFor="jsonUpload" id="jsonUploadLabel">
        JSON Upload<span className="required_label">*</span>
      </label>
    );
  };

  return (
    <>
      <Helmet>
        <title>{"Upload Test from Extension"}</title>
      </Helmet>

      <div className="biform">
        <h1 className="page-header">{"Upload Test from Extension"}</h1>
        <div className="biinstruc">
          <p>
            PS offers a Chrome Extension which allows you to complete Accessibility Indicator Tests and save the results to a JSON file.
            Here you may upload a test completed through the PS AIT Chrome Extension.
          </p>
          Required fields are indicated with a{" "}
          <span className="required_label">*</span>.
        </div>

        <form onSubmit={onSubmit}>
          <div>
            <ComboBox
              label={<AccountNameLabel />}
              selectedKey={accountName}
              onSelectionChange={(selected) => {
                setAccountName(selected);
                let industryId = allAccounts.filter(account => account.companyname === selected)[0] && allAccounts.filter(account => account.companyname === selected)[0].industryid;
                industryId ? setIndustryId(industryId) : setIndustryId(0);
                if (allAccounts.filter(account => account.companyname === selected).length === 0) {
                  setDisableIndustryInput(false);
                } else {
                  setDisableIndustryInput(true);
                }
              }}
              onInputChange={(value) => {
                if (allAccounts.filter(account => account.companyname === value).length === 0) {
                  setDisableIndustryInput(false);
                } else {
                  setDisableIndustryInput(true);
                }
                setCustomAccountName(value);
              }}
              allowsCustomValue
            >
              {allAccounts &&
                allAccounts.map((account) => (
                  <Item key={account.companyname}>{account.companyname}</Item>
                ))}
            </ComboBox>
            <p id="addInfo1" className="additional-info bottom-margin">If an account name doesn't exist in the list, type one in to add it.</p>
          </div>
          <div>
            <ComboBox
              label={<IndustryNameLabel />}
              selectedKey={industryId}
              onSelectionChange={(selected) => setIndustryId(selected)}
              inputValue={industries.filter(industry => industryId === industry.industryid)[0] && industries.filter(industry => industryId === industry.industryid)[0].industryname}
              disabled={disableIndustryInput}
            >
              {industries &&
                industries.map((industry) => (
                  <Item key={industry.industryid}>{industry.industryname}</Item>
                ))}
            </ComboBox>
          </div>
          <JsonUploadLabel />
          <div id="upload-json-landing-screen-upload-container">
            {!fileUploaded &&
              <Dropzone onDrop={acceptedFiles => onFileUpload(acceptedFiles[0])}>
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} accept="application/json" />
                        <svg
                          id="upload-json-landing-screen-upload-icon"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="NoteAddIcon"
                          color="#FE414D"
                        >
                          <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z"></path>
                        </svg>
                        <p id="upload-json-landing-screen-upload-text">Drag and drop a JSON file here, or click or hit enter to select files. Only accepts .json files.</p>
                      </div>
                    </section>
                  </div>
                )}
              </Dropzone>
            }
            {fileUploaded && fileName &&
              <>
                <p id="upload-json-landing-screen-upload-text-bold">File successfully uploaded</p>
                <p id="upload-json-landing-screen-upload-text">{fileName}</p>
                <input
                  type="Button"
                  value="Remove"
                  id="remove-json-landing-screen-button"
                  onClick={() => {
                    setJsonData(null);
                    setFileName("");
                    setFileUploaded(false);
                  }}
                  readOnly
                />
              </>
            }
          </div>
          {fileError && <p className="additional-info bottom-center">Please upload a JSON file before proceeding.</p>}
          <div className="button-container">
            <input type="Submit" className="btn" value="Next" readOnly />
            <div className="spinner-container">
              {loading && <CircularProgress size={24} />}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default UploadJsonLanding;
