import React, { useEffect } from "react";
import Navbar from "./components/Navbar/navbar";
import { Routes, Route } from "react-router-dom"
import EditQuestionnaire from "./components/EditQuestionnaire"
import DashboardGraph from "./components/DashboardGraph";
import DashboardTable from "./components/DashboardTable";
import QuestionList from "./components/QuestionList";
import CompanyGraph from "./components/CompanyGraph";
import PrintButton from "./components/PrintPage";
import ExplanationText from "./components/ExplanationText";
import CompanyTable from "./components/CompanySpecific";
import BackButton from "./components/BackButton"
import Header from "./components/Header"
import IntroScreen from "./components/Intro_Home_Screen/intro-screen";
import AddPagesScreen from "./components/Add_Pages_Screen/add-pages-screen";
import LoginScreen from "./components/Login_Screen/login-screen";
import NewTestScreen from "./components/New_Test_Screen/NewTestScreen";
import BasicInfoScreen from "./components/Basic_Info_Screen/basicinfo";
import ScoringScreen from "./components/Scoring_Screen/scoringscreen";
import ManageTestsScreen from "./components/ManageTestsScreen/ManageTestsScreen";
import UploadTestLanding from "./components/UploadTestLanding/UploadTestLanding";
import UploadJsonLanding from "./components/UploadJsonLanding/UploadJsonLanding";
import { RoundedDiv, SpacedDiv } from "./components/DashboardGraph/__styled__/DashboardGraph";
import Report from "./components/Report";
import { RouteGuard } from './components/RouteGuard/route-guard';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { AuthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { appRoles } from './authConfig';

const adminRoles = [appRoles.admin, appRoles.superAdmin];

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (location.pathname !== "/" && !isAuthenticated && inProgress === InteractionStatus.None) {
      navigate("/");
    }
  }, [location.pathname]);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route
          path="/questionnaire"
          element={
            <main id="mainContent" tabIndex="-1">
              <AuthenticatedTemplate>
                <RouteGuard roles={adminRoles}>
                  <EditQuestionnaire />
                </RouteGuard>
              </AuthenticatedTemplate>
            </main>
          }
        />
        <Route
          path="/questions"
          element={
            <main id="mainContent" tabIndex="-1">
              <AuthenticatedTemplate>
                <RouteGuard roles={adminRoles}>
                  <QuestionList />
                </RouteGuard>
              </AuthenticatedTemplate>
            </main>
          }
        />
        <Route
          path="/accountOverview"
          element={
            <main
              aria-label="Accessibility Dashboard"
              id="mainContent"
              tabIndex="-1"
            >
              <AuthenticatedTemplate>
                <RouteGuard roles={adminRoles}>
                  <h1 className="page-header">Dashboard</h1>
                  <ExplanationText />
                  <RoundedDiv>
                    <DashboardGraph />
                  </RoundedDiv>
                  <SpacedDiv />
                  <DashboardTable />
                  <PrintButton name="Print Page" documentName={`Dashboard Snapshot on ${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}`} />
                </RouteGuard>
              </AuthenticatedTemplate>
            </main>
          }
        />
        <Route path={"/company"}>
          <Route
            path={":companyName"}
            element={
              <main id="mainContent" tabIndex="-1">
                <AuthenticatedTemplate>
                  <RouteGuard roles={adminRoles}>
                    <BackButton returnTo={params.get("from")} testId={params.get("testId")} />
                    <Header headerTitle={location.pathname.substring(location.pathname.lastIndexOf('/') + 1)} />
                    <ExplanationText />
                    <CompanyGraph company={location.pathname.substring(location.pathname.lastIndexOf('/') + 1)} />
                    <SpacedDiv />
                    <CompanyTable company={location.pathname.substring(location.pathname.lastIndexOf('/') + 1)} />
                    <PrintButton name="Print Page" documentName={`Snapshot for ${location.pathname.substring(location.pathname.lastIndexOf('/') + 1)} on ${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}`} />
                  </RouteGuard>
                </AuthenticatedTemplate>
              </main>
            }
          />
        </Route>
        <Route exact path="/" element={<LoginScreen />} />
        <Route path="/home" element={<main id="mainContent" tabIndex="-1"><AuthenticatedTemplate><IntroScreen /></AuthenticatedTemplate></main>} />
        <Route path="/addPages" element={<main id="mainContent" tabIndex="-1"><AuthenticatedTemplate><AddPagesScreen /></AuthenticatedTemplate></main>} />
        <Route path="/NewTest" element={<main id="mainContent" tabIndex="-1"><AuthenticatedTemplate><NewTestScreen /></AuthenticatedTemplate></main>} />
        <Route path="/BasicInfo" element={<main id="mainContent" tabIndex="-1"><AuthenticatedTemplate><BasicInfoScreen /></AuthenticatedTemplate></main>} />
        <Route path="/basicInfo/uploadTest" element={<main id="mainContent" tabIndex="-1"><AuthenticatedTemplate><BasicInfoScreen /></AuthenticatedTemplate></main>} />
        <Route path="/uploadTest" element={<main id="mainContent" tabIndex="-1"><AuthenticatedTemplate><UploadTestLanding /></AuthenticatedTemplate></main>} />
        <Route path="/uploadJson" element={<main id="mainContent" tabIndex="-1"><AuthenticatedTemplate><UploadJsonLanding /></AuthenticatedTemplate></main>} />
        <Route path="/ScoringScreen" element={<main id="mainContent" tabIndex="-1"><AuthenticatedTemplate><ScoringScreen /></AuthenticatedTemplate></main>} />
        <Route
          path="/Results"
          element={
            <main id="mainContent" tabIndex="-1">
              <AuthenticatedTemplate>
                <Report />
              </AuthenticatedTemplate>
            </main>
          }
        />
        <Route
          path="/exportResults"
          element={
            <main id="mainContent" tabIndex="-1">
              <AuthenticatedTemplate>
                <Report />
              </AuthenticatedTemplate>
            </main>
          }
        />
        <Route
          path="/ManageTest"
          element={
            <main id="mainContent" tabIndex="-1">
              <AuthenticatedTemplate>
                <ManageTestsScreen />
              </AuthenticatedTemplate>
            </main>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
