import { LogLevel } from "@azure/msal-browser";

export const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const REACT_APP_TENANT_ID = process.env.REACT_APP_TENANT_ID;
const API_APP_ID_URI = process.env.REACT_APP_API_ID_URI;

export const msalConfig = {
  auth: {
    clientId: REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${REACT_APP_TENANT_ID}`,
    redirectUri: "/home",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
    clientCapabilities: ["CP1"], // this lets the resource owner know that this client is capable of handling claims challenge.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const protectedResources = {
  //not in use
  testEndpoint: {
    endpoint: `${process.env.REACT_APP_API_URL}/testconnection`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  // user- only used to get tests for specific user id
  getTests: {
    endpoint: `${process.env.REACT_APP_API_URL}/tests/`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  //user
  addTest: {
    endpoint: `${process.env.REACT_APP_API_URL}/tests`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    },
  },
  //admin
  getDashboard: {
    endpoint: `${process.env.REACT_APP_API_URL}/dashboard/getOverallDashboard`,
    scopes: {
      read: [`${API_APP_ID_URI}/AllTests.Read`],
    },
  },
  //admin
  getCompanyDashboard: {
    endpoint: `${process.env.REACT_APP_API_URL}/dashboard/getCompanyDashboard/`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  //user
  getIndustries: {
    endpoint: `${process.env.REACT_APP_API_URL}/industries`,
    scopes: {
      read: [`${API_APP_ID_URI}/AllTests.Read`],
    },
  },
  //user
  getReport: {
    endpoint: `${process.env.REACT_APP_API_URL}/reports/`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  //user
  getPDF: {
    endpoint: `${process.env.REACT_APP_API_URL}/reports/getPDF`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  getTitle: {
    endpoint: `${process.env.REACT_APP_API_URL}/reports/getTitle`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  //admin
  deleteCriteria: {
    endpoint: `${process.env.REACT_APP_API_URL}/criteria/`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    },
  },
  //user
  deleteTest: {
    endpoint: `${process.env.REACT_APP_API_URL}/tests/`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    },
  },
  //user
  login: {
    endpoint: `${process.env.REACT_APP_API_URL}/login`,
    scopes: {
      write: [`${API_APP_ID_URI}/Login.ReadWrite`],
    },
  },
  //user
  getTestLength: {
    endpoint: `${process.env.REACT_APP_API_URL}/criteria/getNumQuestions/`,
    scopes: {
      read: [`${API_APP_ID_URI}/TestLength.Read`],
    },
  },
  //admin
  addImage: {
    endpoint: `${process.env.REACT_APP_API_URL}/images`,
    scopes: {
      write: [`${API_APP_ID_URI}/AddImage.ReadWrite`],
    },
  },
  //admin
  updateImage: {
    endpoint: `${process.env.REACT_APP_API_URL}/images`,
    scopes: {
      write: [`${API_APP_ID_URI}/UpdateImage.ReadWrite`],
    },
  },
  //admin
  createReasoning: {
    endpoint: `${process.env.REACT_APP_API_URL}/reasonings`,
    scopes: {
      write: [`${API_APP_ID_URI}/CreateReasoning.ReadWrite`],
    },
  },
  //admin
  updateReasoning: {
    endpoint: `${process.env.REACT_APP_API_URL}/reasonings`,
    scopes: {
      write: [`${API_APP_ID_URI}/UpdateReasoning.ReadWrite`],
    },
  },
  //admin
  createGuidance: {
    endpoint: `${process.env.REACT_APP_API_URL}/feedback`,
    scopes: {
      write: [`${API_APP_ID_URI}/CreateGuidance.ReadWrite`],
    },
  },
  //admin
  updateGuidance: {
    endpoint: `${process.env.REACT_APP_API_URL}/feedback`,
    scopes: {
      write: [`${API_APP_ID_URI}/AddGuidance.ReadWrite`],
    },
  },
  //user
  getCriteriaTypeByName: {
    endpoint: `${process.env.REACT_APP_API_URL}/criteriatypes/byName`,
    scopes: {
      read: [`${API_APP_ID_URI}/CriteriaTypesByName.Read`],
    },
  },
  //admin
  createCriteria: {
    endpoint: `${process.env.REACT_APP_API_URL}/criteria`,
    scopes: {
      write: [`${API_APP_ID_URI}/CreateCriteria.ReadWrite`],
    },
  },
  //admin
  updateCriteria: {
    endpoint: `${process.env.REACT_APP_API_URL}/criteria`,
    scopes: {
      write: [`${API_APP_ID_URI}/UpdateCriteria.ReadWrite`],
    },
  },
  //?
  updateCompletedTest: {
    endpoint: `${process.env.REACT_APP_API_URL}/tests/submitTest`,
    scopes: {
      write: [`${API_APP_ID_URI}/UpdateCompletedTest.ReadWrite`],
    },
  },
  //admin?
  getAllResponses: {
    endpoint: `${process.env.REACT_APP_API_URL}/response/allResponses/`,
    scopes: {
      read: [`${API_APP_ID_URI}/AllResponses.Read`],
    },
  },
  //?
  getTestVersions: {
    endpoint: `${process.env.REACT_APP_API_URL}/testversions`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  //user
  updateBasicInfo: {
    endpoint: `${process.env.REACT_APP_API_URL}/tests/info/:testid`,
    scopes: {
      write: [`${API_APP_ID_URI}/BasicInfo.ReadWrite`],
    },
  },
  //user
  getBasicInfo: {
    endpoint: `${process.env.REACT_APP_API_URL}/tests/info/`,
    scopes: {
      read: [`${API_APP_ID_URI}/BasicInfo.Read`],
    },
  },
  //user
  addNewTest: {
    endpoint: `${process.env.REACT_APP_API_URL}/tests`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    },
  },
  //user- only used for new test dropdown to choose company name
  getAllCompanies: {
    endpoint: `${process.env.REACT_APP_API_URL}/companies`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  //user- only used for new test to choose company name
  getCompany: {
    endpoint: `${process.env.REACT_APP_API_URL}/companies`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  addCompany: {
    endpoint: `${process.env.REACT_APP_API_URL}/companies`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    },
  },
  //admin- used in edit questionnaire
  getAllCriteriaTypes: {
    endpoint: `${process.env.REACT_APP_API_URL}/criteriatypes`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    },
  },
  //admin- used in edit questionnaire
  getCriteriaTypeById: {
    endpoint: `${process.env.REACT_APP_API_URL}/criteriatypes/`,
    scopes: {
      read: [`${API_APP_ID_URI}/CriteriaTypesById.Read`],
    },
  },
  //user- only used in scoring form
  getCriteriaById: {
    endpoint: `${process.env.REACT_APP_API_URL}/criteria/criteriabyid/`,
    scopes: {
      read: [`${API_APP_ID_URI}/CriteriaById.Read`],
    },
  },
  //user 
  addUser: {
    endpoint: `${process.env.REACT_APP_API_URL}/users`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    },
  },
  //user- used in edit questionnaire and scoring form
  getReasoning: {
    endpoint: `${process.env.REACT_APP_API_URL}/reasonings/`,
    scopes: {
      read: [`${API_APP_ID_URI}/Reasonings.Read`],
    },
  },
  //user- only used in scoring form
  getResponseForCriteria: {
    endpoint: `${process.env.REACT_APP_API_URL}/response/`,
    scopes: {
      read: [`${API_APP_ID_URI}/ResponseForCriteria.Read`],
    },
  },
  //user- only used in scoring form
  sendOrUpdateResponse: {
    endpoint: `${process.env.REACT_APP_API_URL}/response/updateOrAdd`,
    scopes: {
      write: [`${API_APP_ID_URI}/UpdateOrSendResponse.ReadWrite`],
    },
  },
  //user
  updateResponse: {
    endpoint: `${process.env.REACT_APP_API_URL}/response`,
    scopes: {
      write: [`${API_APP_ID_URI}/UpdateResponse.ReadWrite`],
    },
  },
  //user
  sendResponse: {
    endpoint: `${process.env.REACT_APP_API_URL}/response`,
    scopes: {
      write: [`${API_APP_ID_URI}/SendResponse.ReadWrite`],
    },
  },
  //user- used in edit questionnaire and scoring form
  getImage: {
    endpoint: `${process.env.REACT_APP_API_URL}/images/list/`,
    scopes: {
      read: [`${API_APP_ID_URI}/Images.Read`],
    },
  },
  //user- used in question list and scoring nav
  getAllCriteria: {
    endpoint: `${process.env.REACT_APP_API_URL}/criteria`,
    scopes: {
      read: [`${API_APP_ID_URI}/UserTests.Read`],
    },
  },
  //admin
  uploadTest: {
    endpoint: `${process.env.REACT_APP_API_URL}/uploadTest`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    },
  },
  getLighthouse: {
    endpoint: `${process.env.REACT_APP_API_URL}/lighthouse/`,
    scopes: {
      read:  [`${API_APP_ID_URI}/UserTests.Read`],
    }
  },
  addLighthouse: {
    endpoint: `${process.env.REACT_APP_API_URL}/lighthouse`,
    scopes: {
      write:  [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    }
  },
  deleteLighthouse: {
    endpoint: `${process.env.REACT_APP_API_URL}/lighthouse`,
    scopes: {
      write:  [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    }
  },
  getPopeTech: {
    endpoint: `${process.env.REACT_APP_API_URL}/popetech/`,
    scopes: {
      read:  [`${API_APP_ID_URI}/UserTests.Read`],
    }
  },
  addPopeTech: {
    endpoint: `${process.env.REACT_APP_API_URL}/popetech`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    },
  },
  updatePopeTechPages: {
    endpoint: `${process.env.REACT_APP_API_URL}/popetech/pages/`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    }
  },
  updatePopeTechInfo: {
    endpoint: `${process.env.REACT_APP_API_URL}/popetech/info/`,
    scopes: {
      write: [`${API_APP_ID_URI}/UserTests.ReadWrite`],
    }
  },
};

export const loginRequest = {
  scopes: [],
};

export const appRoles = {
  admin: "Admin",
  superAdmin: "SuperAdmin"
}
